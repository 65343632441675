import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const heroWidth = "100%"
export const heroHeight = createResponsiveStyles("95vh", "95vh", "100vh")

/**
 * Hero - Container (Box)
 */
export const heroContainerStyles = (image: string): SxStyleProp => ({
  mt: createResponsiveStyles("57px", "77px", "0"),
  zIndex: "hero",
  position: "relative",
  minHeight: heroHeight,
  height: "auto",
  backgroundSize: "cover",
  backgroundPosition: createResponsiveStyles(
    "80% 100%",
    "80% 100%",
    "top center"
  ),
  backgroundImage: `url(${image})`,
  backgroundRepeat: "no-repeat",
  "::before": {
    content: '""',
    top: 0,
    left: 0,
    height: "100%",
    width: heroWidth,
    position: "absolute",
    opacity: createResponsiveStyles(0.87, 0.87, 0.7),
    backgroundImage: createResponsiveStyles(
      `linear-gradient(91deg, white 57%, rgba(255, 255, 255, 0.8) 84%, rgba(255, 255, 255, 0.8) 110%)`,
      `linear-gradient(to right, white 57%, rgba(255, 255, 255, 0.15) 84%, rgba(28, 67, 57, 0) 111%)`
    ),
  },
})

/**
 * Hero Text - Container (Flex)
 */
export const heroTextContainerStyles: SxStyleProp = {
  px: createResponsiveStyles(5, 7, 12),
  py: createResponsiveStyles(0, 5, 5),
  width: heroWidth,
  height: heroHeight,
  justifyContent: "center",
  flexDirection: "column",
  position: "relative",
}

/**
 * Hero Text - Inner (Box)
 */
export const heroTextInnerStyles: SxStyleProp = {
  width: createResponsiveStyles("100%", "60vw", "43vw"),
  textAlign: createResponsiveStyles("center", "left"),
}

/**
 * Hero Text - Paragraph Container (Box)
 */
export const heroTextParagraphContainerStyles: SxStyleProp = {
  whiteSpace: "pre-line",
  pt: createResponsiveStyles(2, 2, 3),
  pb: createResponsiveStyles(5, 4, 3),
  width: createResponsiveStyles("100%", "67%", "83%"),
}

/**
 * Hero Text - Button Container (Box)
 */
export const heroTextButtonContainerStyles: SxStyleProp = {
  pt: createResponsiveStyles(1, 3, 0),
  flexDirection: "column",
  justifyContent: "center",
  alignItems: createResponsiveStyles("center", "flex-start", "flex-start"),
}

/**
 * Hero Text - Button (Link)
 */
export const heroTextButtonStyles: SxStyleProp = {
  variant: "buttons.secondary",
  width: "100%",
  maxWidth: "216px",
  "&:nth-of-type(1)": {
    variant: "buttons.primary",
    mb: createResponsiveStyles(2, 3, 3),
  },
}
