import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const columnedContainerStyles: SxStyleProp = {
  flexGrow: 1,
  bg: "whiteTwo",
  px: createResponsiveStyles(5, 9, 12),
  py: createResponsiveStyles(7, 9, 9),
}

export const columnedHeadingStyles: SxStyleProp = {
  fontWeight: "bold",
  textAlign: "center",
  pb: createResponsiveStyles(0, 2, 6),
}

export const columnedInnerStyles: SxStyleProp = {
  flexGrow: 1,
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: "space-between",
}

export const columnedBlockStyles: SxStyleProp = {
  width: "100%",
  textAlign: "center",
  justifyContent: "center",
  px: createResponsiveStyles(0, 5, 4),
  py: createResponsiveStyles(3, 4, 7),
  maxWidth: createResponsiveStyles("100%", "50%", "25%"),
}

export const columnedBlockIconStyles: SxStyleProp = {
  minHeight: "65px",
  alignItems: "flex-end",
  justifyContent: "center",
}

export const columnedBlockTitleStyles: SxStyleProp = {
  px: createResponsiveStyles(2, "0"),
  pt: createResponsiveStyles(1, 2, 3),
  pb: createResponsiveStyles(1, 2, 2),
  fontWeight: "bold",
  fontSize: 1,
}

export const columnedBlockParagraphStyles: SxStyleProp = {
  px: createResponsiveStyles(2, "0"),
  py: createResponsiveStyles(1, "0"),
}
