/** @jsx jsx */
import {
  contentBlockRowStyles,
  contentBlockTitleStyles,
  contentBlockTextBlockStyles,
  contentBlockContainerStyles,
  contentBlockImageBlockStyles,
  contentBlockTextBlockListStyles,
  contentBlockTextBlockTextStyles,
  contentBlockTextBlockTitleStyles,
} from "./styles"
import { Image } from "./images"
import { jsx, Flex, Box, Styled } from "theme-ui"

/**
 * Type definition for props required for "ContentBlock" component.
 *
 * @interface
 */
interface ContentBlockProps {
  title: string
  data: Array<{
    title: string
    text: string
    list: string[]
  }>
}

/**
 * Component rendering the "ContentBlock" section of the site.
 *
 * 1. Heading (h1)
 * 2. Block
 *   a) Image (Alternate Position)
 *   b) Heading
 *   c) Text (HTML based)
 *
 * Used on "physicians" page.
 *
 * @param {PageContentProps} param
 *
 * @returns {JSX.Element}
 */
const ContentBlock = ({ data, title }: ContentBlockProps) => {
  return (
    <Flex sx={contentBlockContainerStyles}>
      <Styled.h2 sx={contentBlockTitleStyles}>{title}</Styled.h2>
      {data.length
        ? data.map((row, i) => (
            <Flex key={i} sx={contentBlockRowStyles}>
              <Box className={"ImageBox"} sx={contentBlockImageBlockStyles}>
                <Image
                  index={i}
                  sx={{ width: "100%" }}
                  alt={`${row.title} - Image`}
                />
              </Box>
              <Flex sx={contentBlockTextBlockStyles} className={"TextBlock"}>
                <Styled.h3 sx={contentBlockTextBlockTitleStyles}>
                  {row.title}
                </Styled.h3>
                <Styled.p sx={contentBlockTextBlockTextStyles}>
                  {row.text}
                </Styled.p>
                {row.list.length ? (
                  <Box as={"ul"} sx={contentBlockTextBlockListStyles}>
                    {row.list.map((rowData, k) => (
                      <Box as={"li"} key={k}>
                        {rowData}
                      </Box>
                    ))}
                  </Box>
                ) : null}
              </Flex>
            </Flex>
          ))
        : null}
    </Flex>
  )
}

export default ContentBlock
