/** @jsx jsx */
import {
  columnedBlockStyles,
  columnedInnerStyles,
  columnedHeadingStyles,
  columnedContainerStyles,
  columnedBlockIconStyles,
  columnedBlockParagraphStyles,
  columnedBlockTitleStyles,
} from "./styles"
import { Icon } from "./images"
import { capitalize } from "../../helpers/string"
import { Flex, Box, Styled, jsx } from "theme-ui"

/**
 * Type definition for props required for "Columned" component.
 *
 * @interface
 */
interface ColumnedProps {
  title: string
  data: Array<{
    title: string
    text: string
  }>
}

/**
 * Component rendering the "Columned" section of the site.
 *
 * 1. Icon
 * 2. Title
 * 3. Text
 *
 * Used on "home" & "physicians" page.
 *
 * @param {ColumnedProps} param
 *
 * @returns {JSX.Element}
 */
const Columned = ({ data, title }: ColumnedProps): JSX.Element => (
  <Box as={"section"} sx={columnedContainerStyles}>
    <Styled.h2 sx={columnedHeadingStyles}>{title}</Styled.h2>
    <Flex sx={columnedInnerStyles}>
      {data.length
        ? data.map((column, i) => (
            <Flex key={i} sx={columnedBlockStyles}>
              <Box>
                <Flex sx={columnedBlockIconStyles}>
                  <Icon
                    sx={{ g: { fill: "secondary" } }}
                    index={i}
                    alt={`${capitalize(column.title)} - Icon`}
                  />
                </Flex>
                <Styled.p sx={columnedBlockTitleStyles}>
                  {column.title}
                </Styled.p>
                <Styled.p sx={columnedBlockParagraphStyles}>
                  {column.text}
                </Styled.p>
              </Box>
            </Flex>
          ))
        : ""}
    </Flex>
  </Box>
)

export default Columned
