/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { heroContainerStyles } from "./styles"
import HeroText, { HeroTextProps } from "./text"

/**
 * Type definition for props required for "Hero" component.
 *
 * @interface
 */
interface HeroProps extends HeroTextProps {
  image: string
}

/**
 * Component rendering the "Hero" section of the site.
 *
 * Used on "home" & "physicians" page.
 *
 * @param {HeroProps} param
 *
 * @returns {JSX.Element}
 */
const Hero = ({ image, pageTitle, routeId }: HeroProps): JSX.Element => {
  return (
    <Box sx={heroContainerStyles(image)}>
      <HeroText routeId={routeId} pageTitle={pageTitle} />
    </Box>
  )
}

export default Hero
