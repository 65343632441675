/** @jsx jsx */
import { jsx } from "theme-ui"
import { ChangeEvent } from "react"
import { Tabs, Tab } from "@material-ui/core"
import { faqsTabsContainerStyles, faqsTabStyles } from "../../styles"

/**
 * Type definition for title of Tabs.
 *
 * @interface
 */
export interface TabTitles {
  titles: string[]
}

/**
 * Type definition for props required for "TabHeader" component.
 *
 * @interface
 */
interface TabHeaderProps extends TabTitles {
  currentTab: number
  handleChange: (e: ChangeEvent<{}>, tabIndex: number) => void
}

/**
 * Component rendering the "FAQList" in the "FAQ" component.
 *
 * 1. Titles
 *
 * Use only on "home" & "physicians" page.
 *
 * @param {TabHeaderProps} param
 *
 * @returns {JSX.Element}
 */
const TabHeader = ({
  currentTab,
  handleChange,
  titles,
}: TabHeaderProps): JSX.Element => {
  return (
    <Tabs
      role="tablist"
      value={currentTab}
      variant="fullWidth"
      onChange={handleChange}
      aria-label="FAQ Tabs"
      indicatorColor={"primary"}
      sx={faqsTabsContainerStyles}
    >
      {titles.map((title, i) => {
        return (
          <Tab
            key={i}
            role="tab"
            label={title}
            sx={faqsTabStyles}
            id={`faqTab-${i}`}
            aria-selected={!i ? "true" : "false"}
            tabIndex={!i ? 0 : -1}
            aria-controls={`faqTabPanel-${i}`}
          />
        )
      })}
    </Tabs>
  )
}

export default TabHeader
