/** @jsx jsx */
import {
  faqsTabsStyles,
  faqsTabPanelStyles,
  faqsTabsInnerStyles,
} from "./../styles"
import TabPanel from "./panel"
import { jsx, Box } from "theme-ui"
import { AppBar } from "@material-ui/core"
import TabHeader, { TabTitles } from "./header"
import { useState, ChangeEvent, Children, ReactNode } from "react"

/**
 * Type definition for props required for "FAQTab" component.
 *
 * @interface
 */
interface FAQProps extends TabTitles {
  children?: ReactNode
}

/**
 * Component rendering the "FAQTabs" in the "FAQ" component.
 *
 * 1. Tab Heading
 * 2. Tab Panel
 *
 * Use only on "home" & "physicians" page.
 *
 * @param {FAQProps} param
 *
 * @returns {JSX.Element}
 */
const FAQTabs = ({ children, titles }: FAQProps): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<number>(0)

  const handleChange = (event: ChangeEvent<{}>, tabIndex: number) => {
    setCurrentTab(tabIndex)
  }

  return (
    <Box sx={faqsTabsStyles}>
      <AppBar position={"static"} sx={faqsTabsInnerStyles}>
        <TabHeader
          titles={titles}
          currentTab={currentTab}
          handleChange={handleChange}
        />
      </AppBar>
      {Children.map(children, (child, i) => {
        return (
          <TabPanel
            sx={faqsTabPanelStyles}
            key={i}
            index={i}
            value={currentTab}
          >
            {child}
          </TabPanel>
        )
      })}
    </Box>
  )
}

export default FAQTabs
