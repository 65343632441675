import { FluidObject } from "gatsby-image"

/**
 * Type declaration for default Image Types
 *
 * Mainly used for querying images through GraphQL
 *
 * @type
 */
type ImageTypes = "mobileImage" | "tabletImage" | "desktopImage"

/**
 * Type definition for props required for rendering Image Components
 *
 * @type
 */
interface ImageSourceProps {
  data: {
    [key in ImageTypes]: {
      childImageSharp: {
        fluid: any
      }
    }
  }
}

/**
 * Type definition for different image media that can be queried for.
 *
 * Mainly used for querying images through GraphQL
 *
 * @enum
 */
export enum ImageMedia {
  all = "all",
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
}

/**
 * Create different image sources and get images by image media.
 *
 * 1. https://www.gatsbyjs.org/packages/gatsby-image/#avoiding-stretched-images-using-the-fluid-type
 * 2. https://www.gatsbyjs.org/packages/gatsby-image/#art-directing-multiple-images
 *
 * @function
 *
 * @param {ImageSourceProps} param
 * @param {ImageMedia} mediaType
 *
 * @returns {FluidObject[]}
 */
export const createSources = (
  { data }: ImageSourceProps,
  mediaType: ImageMedia = ImageMedia.all
): FluidObject[] => {
  const mobileImage = data.mobileImage.childImageSharp.fluid
  const tabletImage = {
    ...data.tabletImage.childImageSharp.fluid,
    media: `(min-width: 768px)`,
  }
  const desktopImage = {
    ...data.desktopImage.childImageSharp.fluid,
    media: `(min-width: 1024px)`,
  }

  let sources = []

  switch (mediaType) {
    case ImageMedia.mobile:
      sources = mobileImage
      break
    case ImageMedia.tablet:
      sources = tabletImage
      break
    case ImageMedia.desktop:
      sources = desktopImage
      break
    default:
      sources = [mobileImage, tabletImage, desktopImage]
      break
  }

  return sources
}
