import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const statsContainerStyles: SxStyleProp = {
  alignItems: "center",
  flexDirection: "column",
}

export const statsBlockStyles = (index: number): SxStyleProp => ({
  width: "100%",
  flexDirection: createResponsiveStyles(
    "column-reverse",
    index % 2 === 0 ? "row" : "row-reverse",
    index % 2 === 0 ? "row" : "row-reverse"
  ),
})

export const statsBlockImageStyles: SxStyleProp = {
  width: "100%",
  maxWidth: createResponsiveStyles("100%", "50%", "50%"),
}

export const statsBlockTextBlockStyles: SxStyleProp = {
  width: "100%",
  bg: "whiteTwo",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  px: createResponsiveStyles(9, 9, 9),
  py: createResponsiveStyles(11, 0, 0),
  maxWidth: createResponsiveStyles("100%", "50%", "50%"),
}

export const statsBlockTextBlockMainStyles: SxStyleProp = {
  fontWeight: "bold",
  py: createResponsiveStyles(1, 0, 0),
  fontSize: createResponsiveStyles(5, 5, 10),
}

export const statsBlockTextBlockTextStyles: SxStyleProp = {
  width: "100%", // IE Fix
  fontFamily: "heading",
  fontSize: createResponsiveStyles(1, 1, 3),
}
