/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { ImageProps } from "../../../interfaces/image"
import { createSources } from "../../../helpers/image"

/**
 * Component rendering the first image for the "ContentBlock" component.
 *
 * @param {ImageProps} param
 *
 * @returns {JSX.Element}
 */
export const FirstImage = ({ sx, alt }: ImageProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(
        relativePath: { eq: "physicians/content-block-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 530) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tabletImage: file(
        relativePath: { eq: "physicians/content-block-1@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1060) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(
        relativePath: { eq: "physicians/content-block-1@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1590) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = createSources({ data })

  return <Img sx={sx} fluid={sources} alt={alt} />
}
