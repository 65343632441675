/** @jsx jsx */
import { jsx } from "theme-ui"
import FAQListItem from "./item"
import { List } from "@material-ui/core"
import { FAQ } from "../../../../interfaces/faqs"
import { faqsListContainerStyles } from "../../styles"

/**
 * Type definition for props required for "FAQList" component.
 *
 * @interface
 */
interface FAQListProps extends FAQ {
  index: number
}

/**
 * Component rendering the "FAQList" in the "FAQ" component.
 *
 * 1. List Items
 *
 * Use only on "home" & "physicians" page.
 *
 * @param {FAQListProps} param
 *
 * @returns {JSX.Element}
 */
const FAQList = ({ index, faq }: FAQListProps) => {
  return (
    <List
      sx={faqsListContainerStyles}
      component="nav"
      aria-labelledby={`faqTabContent-${index}`}
    >
      {faq.map((item, i) => (
        <FAQListItem
          openState={i === 0 ? true : false}
          question={item.question}
          answer={item.answer}
          key={i}
        />
      ))}
    </List>
  )
}

export default FAQList
