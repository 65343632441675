/** @jsx jsx */
import {
  statsBlockStyles,
  statsContainerStyles,
  statsBlockImageStyles,
  statsBlockTextBlockStyles,
  statsBlockTextBlockTextStyles,
  statsBlockTextBlockMainStyles,
} from "./styles"
import { Image } from "./images"
import { jsx, Flex, Box, Styled } from "theme-ui"

/**
 * Type definition for props required for "Stats" component.
 *
 * @interface
 */
interface StatsProps {
  data: Array<{
    preText: string
    main: string
    text: string
  }>
}

/**
 * Component rendering the "Stats" section of the site.
 *
 * 1. Image (Alternate Position)
 * 2. Text
 *   a) Pre-Text
 *   b) Main - Heading
 *   c) Text
 *
 * Used on "home" & "physicians" page.
 *
 * @param {StatsProps} param
 *
 * @returns {JSX.Element}
 */
const Stats = ({ data }: StatsProps): JSX.Element => {
  return (
    <Flex as={"section"} sx={statsContainerStyles}>
      {data.length
        ? data.map((statsData, i) => (
            <Flex key={i} sx={statsBlockStyles(i)}>
              <Box sx={statsBlockImageStyles}>
                <Image index={i} alt={`Stats Image - ${i}`} />
              </Box>
              <Flex sx={statsBlockTextBlockStyles}>
                {statsData.preText.length ? (
                  <Styled.p sx={statsBlockTextBlockTextStyles}>
                    {statsData.preText}
                  </Styled.p>
                ) : null}
                <Styled.h2 sx={statsBlockTextBlockMainStyles}>
                  {statsData.main}
                </Styled.h2>
                <Styled.p sx={statsBlockTextBlockTextStyles}>
                  {statsData.text}
                </Styled.p>
              </Flex>
            </Flex>
          ))
        : ""}
    </Flex>
  )
}

export default Stats
