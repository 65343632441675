import React from "react"
import { ImageProps } from "../../../interfaces/image"
import thirdIcon from "../../../images/common/icons/process/step-3.svg"

/**
 * Component rendering the third icon for the "Process" component.
 *
 * @param {ImageProps} param
 *
 * @returns {JSX.Element}
 */
export const Icon = ({ className, alt }: ImageProps): JSX.Element => {
  return <img src={thirdIcon} className={className} alt={alt} />
}
