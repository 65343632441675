/** @jsx jsx */
import {
  processStepStyles,
  processTitleStyles,
  processInnerStyles,
  processContainerStyles,
  processTextBlockStyles,
  processImageBlockStyles,
  processTextBlockTitleStyles,
  processTextBlockParagraphStyles,
} from "./styles"
import { Icon } from "./images"
import { jsx, Flex, Styled, Box } from "theme-ui"

/**
 * Type definition for props required for "Process" component.
 *
 * @interface
 */
interface ProcessProps {
  title: string
  data: Array<{
    title: string
    text: string
  }>
}

/**
 * Component rendering the "Process" section of the site.
 *
 * 1. Icon
 * 2. Text
 *   a) Heading
 *   b) Text
 *
 * Used on "home" & "physicians" page.
 *
 * @param {StatsProps} param
 *
 * @returns {JSX.Element}
 */
const Process = ({ title, data }: ProcessProps): JSX.Element => {
  return (
    <Flex sx={processContainerStyles}>
      <Styled.h2 sx={processTitleStyles}>{title}</Styled.h2>
      <Flex sx={processInnerStyles}>
        {data.length
          ? data.map((process, i) => {
              return (
                <Flex key={i} sx={processStepStyles}>
                  <Box sx={processImageBlockStyles}>
                    <Icon
                      index={i}
                      sx={{ width: "100%" }}
                      alt={`Step - ${i} Icon`}
                    />
                  </Box>
                  <Flex sx={processTextBlockStyles}>
                    <Styled.h5 sx={processTextBlockTitleStyles}>
                      {process.title}
                    </Styled.h5>
                    <Styled.p sx={processTextBlockParagraphStyles}>
                      {process.text}
                    </Styled.p>
                  </Flex>
                </Flex>
              )
            })
          : null}
      </Flex>
    </Flex>
  )
}

export default Process
