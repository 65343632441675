import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const processContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  py: createResponsiveStyles(7, 9, 12),
}

export const processTitleStyles: SxStyleProp = {
  fontWeight: "bold",
  pb: createResponsiveStyles("0", "0", 6),
}

export const processInnerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-around",
}

export const processStepStyles: SxStyleProp = {
  width: createResponsiveStyles("100%", "100%", "80%"),
  px: createResponsiveStyles(5, 7, 13),
  alignItems: "flex-start",
  justifyContent: createResponsiveStyles(
    "flex-start",
    "flex-start",
    "flex-start"
  ),
  py: createResponsiveStyles(4, 5, 6),
  "&:nth-of-type(2n)": {
    justifyContent: createResponsiveStyles(
      "flex-start",
      "flex-start",
      "flex-end"
    ),
  },
}

export const processTextBlockStyles: SxStyleProp = {
  width: createResponsiveStyles("100%", "75%", "40%"),
  alignContent: "flex-start",
  flexDirection: "column",
}

export const processImageBlockStyles: SxStyleProp = {
  height: "100%",
  mt: createResponsiveStyles(1, "0"),
  pr: createResponsiveStyles(3, 6),
  maxWidth: createResponsiveStyles("100px", "145px", "162px"),
}

export const processTextBlockTitleStyles: SxStyleProp = {
  pt: createResponsiveStyles("0", "0", 2),
  fontSize: createResponsiveStyles(1, 3, 3),
  fontWeight: "light",
  color: "primary",
}

export const processTextBlockParagraphStyles: SxStyleProp = {
  pt: 2,
  fontWeight: createResponsiveStyles("bold", "bold", "dark"),
  fontSize: createResponsiveStyles(0, 2),
}
