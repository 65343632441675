/** @jsx jsx */
import {
  heroTextInnerStyles,
  heroTextButtonStyles,
  heroTextContainerStyles,
  heroTextButtonContainerStyles,
  heroTextParagraphContainerStyles,
} from "./styles"
import { useIntl } from "react-intl"
import { Link } from "gatsby-plugin-intl"
import { jsx, Flex, Box, Styled } from "theme-ui"
import { form, learnMore } from "../../routes/common"
import { translate, translateHtml } from "../../helpers/grammar"

/**
 * Type definition for props required for "HeroText" component.
 *
 * @interface
 */
export interface HeroTextProps {
  routeId: string
  pageTitle: string
}

/**
 * Component rendering the "HeroText" section of the site.
 *
 * 1. Title
 * 2. Paragraph (HTML based)
 * 3. Form Button (Forms Page)
 * 4. FAQ Button (Learn More)
 *
 * Used on "home" & "physicians" page.
 *
 * @param {HeroTextProps} param
 *
 * @returns {JSX.Element}
 */
const HeroText = ({ routeId, pageTitle }: HeroTextProps): JSX.Element => {
  const intl = useIntl()
  const formLink = form({ intl })
  const learnMoreLink = learnMore({ intl })

  return (
    <Flex sx={heroTextContainerStyles}>
      <Box sx={heroTextInnerStyles}>
        <Styled.h1 sx={{ fontWeight: "bold" }}>
          {translate(intl, `${pageTitle}.hero.title`)}
        </Styled.h1>
        <Box sx={heroTextParagraphContainerStyles}>
          <Styled.p
            sx={{
              my: [0, 1, 2, 2, 3], // 0 for iPhone 5 (< 375px), 1 for iPhone 6 (>= 375px)
            }}
          >
            {translateHtml(intl, `${pageTitle}.hero.description`)}
          </Styled.p>
        </Box>
        <Flex sx={heroTextButtonContainerStyles}>
          <Link
            id={"HeroFormButton"}
            to={formLink.path}
            sx={heroTextButtonStyles}
            state={{ routeId }}
          >
            {translate(intl, `${pageTitle}.hero.formButton`)}
          </Link>
          <a href={learnMoreLink.path} sx={heroTextButtonStyles}>
            {learnMoreLink.title}
          </a>
        </Flex>
      </Box>
    </Flex>
  )
}

export default HeroText
