import React from "react"
import {
  translate,
  translateHtml,
  stringBasedArrayIndices,
} from "../helpers/grammar"
import SEO from "../components/seo"
import { useIntl } from "react-intl"
import Hero from "../components/hero"
import FAQs from "../components/faqs"
import Stats from "../components/stats"
import Process from "../components/process"
import Columned from "../components/columned"
import MainLayout from "../components/layouts/main"
import ContentBlock from "../components/content-block"
import heroImage from "../images/physicians/physicians-hero@2x.jpg"

/**
 * Component rendering the "/physicians" page of the site.
 *
 * @returns {JSX.Element}
 */
const PhysiciansPage = (): JSX.Element => {
  const intl = useIntl()

  // Used for passing previous route when the user
  // goes to "/forms" page
  const routeId = "physicians"

  // Used for "HeroText" (inside "Hero") and
  // "FixedCTA" (inside "Footer") components.
  const pageTitle = "physicians"

  // Translate text content for the page
  const columnedData = stringBasedArrayIndices(4).map(item => ({
    title: translate(intl, `physicians.weAreHereToHelp.${item}.title`),
    text: translate(intl, `physicians.weAreHereToHelp.${item}.text`),
  }))
  const processData = stringBasedArrayIndices(4).map(item => ({
    title: translate(intl, `physicians.process.${item}.title`),
    text: translate(intl, `physicians.process.${item}.text`),
  }))
  const statsData = stringBasedArrayIndices(3).map(item => ({
    preText:
      item !== "second"
        ? translate(intl, `physicians.stats.${item}.preText`)
        : "",
    main: translate(intl, `physicians.stats.${item}.main`),
    text: translate(intl, `physicians.stats.${item}.text`),
  }))
  const contentBlockData = stringBasedArrayIndices(2).map(item => ({
    title: translate(intl, `physicians.contentBlock.${item}.title`),
    text: translate(intl, `physicians.contentBlock.${item}.text`),
    list:
      item === "second"
        ? new Array(4)
            .fill(4)
            .map((value, i) =>
              translate(intl, `physicians.contentBlock.${item}.list.${i}`)
            )
        : [],
  }))
  const faqsData = stringBasedArrayIndices(3).map((item, i) => ({
    title: translate(intl, `physicians.faqs.${item}.title`),
    questions: stringBasedArrayIndices(i === 2 ? 5 : 3).map((value, k) => ({
      question: translate(
        intl,
        `physicians.faqs.${item}.questions.${k}.question`
      ),
      answer: translateHtml(
        intl,
        `physicians.faqs.${item}.questions.${k}.answer`
      ),
    })),
  }))

  // Render the component using "MainLayout"
  return (
    <MainLayout routeId={routeId} pageTitle={pageTitle} hasCTA={true}>
      <SEO title={translate(intl, "physicians.title")} />
      <Hero routeId={routeId} image={heroImage} pageTitle={pageTitle} />
      <div id="learnMore" />
      <Columned
        data={columnedData}
        title={translate(intl, `physicians.weAreHereToHelp.title`)}
      />
      <ContentBlock
        data={contentBlockData}
        title={translate(intl, `physicians.contentBlock.title`)}
      />
      <Process
        data={processData}
        title={translate(intl, `physicians.process.title`)}
      />
      <Stats data={statsData} />
      <FAQs data={faqsData} title={translate(intl, `physicians.faqs.title`)} />
    </MainLayout>
  )
}

export default PhysiciansPage
