import React from "react"
import { FirstImage } from "./first"
import { SecondImage } from "./second"
import { ThirdImage } from "./third"
import { ImageProps } from "../../../interfaces/image"

export { FirstImage } from "./first"
export { SecondImage } from "./second"
export { ThirdImage } from "./third"

/**
 * Component rendering images for the "Stats" component.
 *
 * @param {ImageProps & {index: number}} param
 *
 * @returns {JSX.Element}
 */
export const Image = ({
  index,
  alt,
}: ImageProps & { index: number }): JSX.Element => {
  switch (index + 1) {
    case 2:
      return <SecondImage alt={alt} />
    case 3:
      return <ThirdImage alt={alt} />
    default:
      return <FirstImage alt={alt} />
  }
}
