import { createResponsiveStyles } from "../../../helpers/theme"
import { SxStyleProp } from "theme-ui"

export const faqsContainerStyles: SxStyleProp = {
  width: "100%",
  bg: "whiteTwo",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  pt: createResponsiveStyles(7, 9, 9),
  pb: createResponsiveStyles("0", "0", 9),
  px: createResponsiveStyles("0", "0", 9),
}

export const faqsHeadingStyles: SxStyleProp = {
  color: "primary",
  maxWidth: "80%",
  fontWeight: "bold",
  textAlign: "center",
  pb: createResponsiveStyles(6, 7, 7),
}

export const faqsTabsStyles: SxStyleProp = {
  width: "100%",
  maxWidth: createResponsiveStyles("100%", "100%", "80%"),
}

export const faqsTabsInnerStyles: SxStyleProp = {
  bg: "whiteTwo",
  "&&": {
    bg: "white",
    zIndex: "tabs",
    color: "text",
    boxShadow: "none",
  },
}

export const faqsTabsContainerStyles: SxStyleProp = {
  color: "text",
  bg: "whiteTwo",
  ".MuiTabs-indicator": {
    top: "0",
    height: "3px",
    bg: "secondary",
    bottom: "auto",
  },
}

export const faqsTabStyles: SxStyleProp = {
  "&&": {
    py: 3,
    opacity: 1,
    fontSize: createResponsiveStyles(1, 1, 3),
    fontWeight: "dark",
    fontFamily: "body",
    textTransform: "none",
    letterSpacing: "unset",
    lineHeight: "unset",
    "&:focus": {
      outline: "none",
    },
  },
  "&.Mui-selected": {
    bg: "white",
    borderBottom: "none",
  },
}

export const faqsTabPanelStyles: SxStyleProp = {
  bg: "white",
  outline: "none",
}

export const faqsListContainerStyles: SxStyleProp = {
  width: "100%",
  svg: {
    minWidth: "27px",
  },
}

export const faqsListInnerStyles: SxStyleProp = {
  mx: "16px",
  ":not(:first-of-type)": {
    pt: 2,
  },
  ":not(:last-of-type)": {
    pb: 1,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "blackBorder",
    ".MuiCollapse-container .MuiListItemText-root:last-of-type": {
      pb: 3,
    },
  },
  ".MuiListItem-gutters": {
    px: 0,
  },
}

export const faqsListTitleBlockStyles: SxStyleProp = {
  "&.active": {
    color: "primary",
  },
  ":hover": {
    cursor: "pointer",
    backgroundColor: "white",
  },
  span: {
    fontFamily: "body",
    fontWeight: "dark",
    fontSize: createResponsiveStyles(0, 1),
    width: createResponsiveStyles("80%", "80%", "100%"),
    letterSpacing: "unset",
    lineHeight: "unset",
  },
}

export const faqsListTitleStyles: SxStyleProp = {
  "&&": {
    pr: createResponsiveStyles(3, 8, 6),
  },
}

export const faqsListItemStyles: SxStyleProp = {
  fontFamily: "body",
  whiteSpace: "pre-line",
  flexDirection: "column",
  "&&": {
    alignItems: "flex-start",
  },
  ":hover": {
    backgroundColor: "white",
  },
  ".MuiListItemText-root": {
    width: "100%",
    pr: createResponsiveStyles(1, 3, 6),
    pb: createResponsiveStyles(2, 3, 4),
    ":last-child": {
      pb: "0",
    },
  },
  span: {
    fontFamily: "body",
    fontSize: 0,
    letterSpacing: "unset",
  },
}
