/** @jsx jsx */
import { ReactNode } from "react"
import { jsx, Flex, Box } from "theme-ui"

/**
 * Type definition for props required for "TabPanel" component.
 *
 * @interface
 */
interface TabPanelProps {
  key?: number
  children?: ReactNode
  index: number
  value: number
}

/**
 * Component rendering the "TabPabel" in the "FAQTab" component.
 *
 * 1. Children
 *
 * Use only on "home" & "physicians" page.
 *
 * @param {TabPanelProps} param
 *
 * @returns {JSX.Element}
 */
const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Flex
      as="div"
      role="tabpanel"
      sx={{ display: value !== index ? "none" : "flex" }}
      id={`faqTabPanel-${index}`}
      aria-labelledby={`faqTab-${index}`}
      tabIndex={0}
      {...other}
    >
      <Box sx={{ width: "100%", p: [1, 1, 1, 3] }}>{children}</Box>
    </Flex>
  )
}

export default TabPanel
