import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const contentBlockContainerStyles: SxStyleProp = {
  bg: "primary",
  color: "white",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  py: createResponsiveStyles(7, 9, 9),
}

export const contentBlockTitleStyles: SxStyleProp = {
  color: "white",
  fontWeight: "bold",
  pb: createResponsiveStyles(4, 6),
}

export const contentBlockRowStyles: SxStyleProp = {
  flexDirection: createResponsiveStyles("column-reverse", "row-reverse", "row"),
  alignItems: "center",
  py: createResponsiveStyles(4, 3, 7),
  width: createResponsiveStyles("95%", "90%", "85%"),
  "& .TextBlock": {
    pr: createResponsiveStyles(3, 6, 2),
    pl: createResponsiveStyles(3, 0, 10),
    pb: createResponsiveStyles(5, 0, 0),
    textAlign: createResponsiveStyles("center", "left"),
  },
  ":nth-of-type(n+2)": {
    alignItems: createResponsiveStyles("flex-start", "center"),
    flexDirection: createResponsiveStyles(
      "column-reverse",
      "column-reverse",
      "row-reverse"
    ),
    "& .ImageBox": {
      width: createResponsiveStyles("100%", "70%", "45%"),
    },
    "& .TextBlock": {
      textAlign: "left",
      pr: createResponsiveStyles(3, 2, 10),
      pl: createResponsiveStyles(3, 0, 5),
      pb: createResponsiveStyles(5, 4, 0),
    },
  },
}

export const contentBlockImageBlockStyles: SxStyleProp = {
  justifyContent: "center",
  width: createResponsiveStyles("100%", "100%", "45%"),
  px: createResponsiveStyles(3, 0),
}

export const contentBlockTextBlockStyles: SxStyleProp = {
  flexDirection: "column",
  justifyContent: "center",
  width: createResponsiveStyles("100%", "100%", "55%"),
}

export const contentBlockTextBlockTitleStyles: SxStyleProp = {
  color: "white",
  fontWeight: "light",
  pb: createResponsiveStyles(2, 2, 2),
  fontSize: createResponsiveStyles(2, 7),
  textAlign: createResponsiveStyles("center", "left"),
}

export const contentBlockTextBlockTextStyles: SxStyleProp = {
  fontWeight: "dark",
}

export const contentBlockTextBlockListStyles: SxStyleProp = {
  pt: createResponsiveStyles(2, 4, 2),
  "> li": {
    fontWeight: "dark",
    textAlign: "left",
  },
}
