/** @jsx jsx */
import FAQTabs from "./tabs"
import FAQList from "./tabs/list"
import { jsx, Flex, Styled } from "theme-ui"
import { Question } from "../../interfaces/faqs"
import { faqsHeadingStyles, faqsContainerStyles } from "./styles"

/**
 * Type definition for props required for "FAQs" component.
 *
 * @interface
 */
interface FAQsProps {
  title: string
  data: Array<{
    title: string
    questions: Question[]
  }>
}

/**
 * Component rendering the FAQs section of the site.
 *
 * 1. Heading
 * 2. FAQ Tabs
 * 3. FAQ List
 *
 * Use only on "home" & "physicians" page.
 *
 * @param {FAQsProps} param
 *
 * @returns {JSX.Element}
 */
const FAQs = ({ title, data }: FAQsProps): JSX.Element => {
  const titles = (): string[] => data.map((faqs, i) => faqs.title)
  const questions = () => data.map((faqs, i) => faqs.questions)

  return (
    <Flex sx={faqsContainerStyles} id={"faqs"}>
      <Styled.h2 sx={faqsHeadingStyles}>{title}</Styled.h2>
      {data.length ? (
        <FAQTabs titles={titles()}>
          {questions().map((faq, i) => (
            <FAQList index={i} faq={faq} key={i} />
          ))}
        </FAQTabs>
      ) : null}
    </Flex>
  )
}

export default FAQs
