/** @jsx jsx */
import {
  faqsListItemStyles,
  faqsListTitleStyles,
  faqsListInnerStyles,
  faqsListTitleBlockStyles,
} from "../../../styles"
import { useState } from "react"
import { jsx, Box } from "theme-ui"
import { Question } from "../../../../../interfaces/faqs"
import { CollapsedIcon, ExpandedIcon } from "../../../images"
import { ListItem, ListItemText, Collapse } from "@material-ui/core"

/**
 * Component rendering the "FAQListItem" in the "FAQList" component.
 *
 * 1. Item Heading
 * 2. Item Text (HTML Based)
 *
 * Use only on "home" & "physicians" page.
 *
 * @param {Question & { openState?: boolean }} param
 *
 * @returns {JSX.Element}
 */
const FAQListItem = ({
  question,
  answer,
  openState,
}: Question & { openState?: boolean }) => {
  const [isOpen, setIsOpen] = useState<boolean>(openState || false)

  /**
   * Handles "onClick" event on "ListItem" heading.
   * Updates "isOpen" to true if false & false if already true.
   *
   * @function
   */
  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box sx={faqsListInnerStyles}>
      <ListItem
        sx={faqsListTitleBlockStyles}
        button={false}
        onClick={handleClick}
        className={isOpen ? "active" : ""}
        aria-expanded={isOpen ? "true" : "false"}
        aria-labelledby="faqs-list-item"
      >
        <ListItemText sx={faqsListTitleStyles} primary={question} />
        {!isOpen ? (
          <CollapsedIcon alt={"Collapsed Icon"} />
        ) : (
          <ExpandedIcon alt={"Expanded Icon"} />
        )}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit={true}>
        <ListItem sx={faqsListItemStyles} button={false}>
          <ListItemText primary={answer} />
        </ListItem>
      </Collapse>
    </Box>
  )
}

export default FAQListItem
